import React, { Fragment, useContext, useEffect, useState } from 'react'
import { LeadershipAPI } from 'api'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'

import LeadershipItem from 'components/MemberItem/Leadership'
import SkeletonItem from 'components/SkeletonItem'
import dayjs from 'dayjs'
import { UserContext } from 'context/UserContext'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function Leadership() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentYear] = useState(dayjs().format('YYYY'))
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        getLeadershipMembers()
    }, [])

    const getLeadershipMembers = async () => {
        const { data } = await LeadershipAPI.get()

        if (data.success) {
            setMembers(data.users)
            return setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onMemberDetails = (member) => {
        navigate(`/members/profile/${member._id}`)
    }

    const onSearchMember = (e) => {
        setSearchTerm(e.target.value.toLowerCase())
    }

    const filteredMembers = members
        .map((member, index) => ({ ...member, originalIndex: index }))
        .filter((member) => member.name.toLowerCase().includes(searchTerm))

    return (
        <Fragment>
            <Header
                className="mb-12"
                onBackward={onBackward}
                title="Tablero de Líderes"
                description={`Miembros que más ruedan (${currentYear})`}
            />

            <div className="mb-6 relative">
                <MagnifyingGlassIcon
                    className={`w-5 absolute top-1/2 -translate-y-1/2 left-4 z-10 text-text`}
                />
                <input
                    onChange={onSearchMember}
                    placeholder="Busca miembro por nombre..."
                    className={`w-full rounded-lg py-2 pr-4 pl-12 border border-transparent focus:border-primary bg-dark-500 placeholder:text-text placeholder:text-sm`}
                />
            </div>

            <div className="flex flex-col gap-6">
                {loading ? (
                    [...Array(10).keys()].map((index) => (
                        <SkeletonItem key={index} />
                    ))
                ) : (
                    <Fragment>
                        {filteredMembers.map((item) => (
                            <LeadershipItem
                                position={item.originalIndex + 1}
                                key={item._id}
                                data={item}
                                isMe={item._id === userState.user._id}
                                onClick={onMemberDetails}
                            />
                        ))}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}
